<template>
  <div class="d-flex flex-column">
    <section class="wet-section">
      <div class="container">
        <h3 class="empty-ph is-size-6">
          {{ droughtRisk.title }}
        </h3>
        <wet-conditions
          :conditions="conditions"
          class="mb-4"
          @select="0"
        />
        <h3 class="empty-ph is-size-6">
          {{ format(droughtRisk.title_table, $moment().format('DD MMMM YYYY')) }}
        </h3>
        <div class="empty-ph is-size-6 mb-3">
          {{ droughtRisk.cache_flow_description }}
        </div>
        <div id="drought-risk-question" class="empty-ph is-size-6 mb-3">
          <p><strong>{{ droughtRisk.cache_flow_cta }}</strong></p>
          <button
            id="drought-risk-cf-cta-no"
            class="wet-button wet-button-md empty-ph mr-2"
            :class="{
              'wet-button-outlined-primary': cfUserAnswer !== false,
            }"
            type="button"
            @click="onCfNo"
          >{{ stringsCommonApp.no }}</button>
          <button
            id="drought-risk-cf-cta-yes"
            class="wet-button wet-button-md empty-ph"
            :class="{
              'wet-button-outlined-primary': cfUserAnswer !== true,
            }"
            type="button"
            @click="onCfYes"
          >{{ stringsCommonApp.yes }}</button>
        </div>
        <div v-if="askResultMessage" class="wet-dr__ask-result mb-5">
          <div class="wet-dr__ask-message mb-3">
            {{ askResultMessage }}
          </div>
          <form
            v-if="cfUserAnswer === false"
            id="wet-dr-feedback-form"
            @submit.prevent="onFeedbackSubmit"
          >
            <input-text
              labeled
              class="mb-3"
              type="tel"
              inputmode="tel"
              :label="stringsCommonApp.phone"
              hideDetails="auto"
              v-model="$v.feedbackForm.phone.$model"
              :errors="$v.feedbackForm.phone.$error ? [stringsCommonApp.error_phone] : []"
            />
            <input-text-area
              labeled
              class="mb-3"
              :label="droughtRisk.feedback_message_label"
              hideDetails="auto"
              v-model="$v.feedbackForm.message.$model"
              :errors="$v.feedbackForm.message.$error ? [stringsCommonApp.error_required] : []"
            />
            <button
              class="wet-button empty-ph"
              type="submit"
              :disabled="!!$v.feedbackForm.$error"
            >{{ droughtRisk.feedback_submit }}</button>
          </form>
        </div>
        <cache-flow
          :crops="crops"
          :cropsTitles="cropsTitles"
          :apiData="apiData"
          :apiYieldHistory="apiYieldHistory"
        />
        <per-crop-overview
          v-show="showCropOverview"
          ref="per-crop-overview"
          class="mt-5"
          :apiData="apiData"
          :apiYieldHistory="apiYieldHistory"
          @maxAmounts="(v) => maxAmounts = v"
          @on-insure="onInsure"
        />
      </div>
    </section>
  </div>
</template>
<script>
import { mapState, mapActions, mapMutations } from 'vuex';
import common from '@/mixins/common';
import droughtRiskMixin from '@/mixins/droughtRisk';
import {
  required,
} from 'vuelidate/lib/validators';
import {
  phone,
} from '@/common/validations';
import { postOrderProduct } from '@/api/order';
import {
  availableWeatherTypes,
  availableWeatherDirs,
} from '@/constants';
import partnerCodeMixin from '@/mixins/partner-code';
import wpSection from '@/enums/wpSection';
import LogService from '@/services/LogService';
import businessType from '@/enums/businessTypes';

export default {
  name: 'DroughtRisk',
  mixins: [common, droughtRiskMixin, partnerCodeMixin],
  components: {
    wetConditions: () => import(/* webpackChunkName: "wetConditions" */ '@/components/WetConditions.vue'),
    cacheFlow: () => import(/* webpackChunkName: "cacheFlow" */ '@/components/drought-risk/cacheFlow.vue'),
    inputText: () => import(/* webpackChunkName: "inputText" */ '@/components/ui/inputTextUi.vue'),
    inputTextArea: () => import(/* webpackChunkName: "inputTextArea" */ '@/components/ui/inputTextareaUi.vue'),
    perCropOverview: () => import(/* webpackChunkName: "perCropOverview" */ '@/components/drought-risk/PerCropOverview.vue'),
  },
  data() {
    return {
      wpApiDroughtRisk: '/wp-json/acf/v3/options/drought-risk',
      wpApiDrought: '/wp-json/acf/v3/options/drought',
      getCropSelectionApi: '/api/risk_assessment/crop_selection',
      saveRiskDataApi: '/api/risk_assessment/save_risk_data',
      yieldApi: '/api/risk_assessment/yield',
      findStaApi: '/api/station/find',
      optimizerApi: '/api/order/optimizer/',
      orderApi: '/api/order/',
      orderSetParameterApi: '/api/order/set_parameter/',
      apiData: {},
      apiYieldHistory: {},
      soureTypesApi: '/api/product/source_types',
      searchCountries: ['DE'],
      cfUserAnswer: null,
      feedbackForm: {
        phone: '',
        message: '',
      },
      feedbackSentStatus: false,
      showCropOverview: false,
      maxAmounts: {},
    };
  },
  computed: {
    ...mapState(['strings', 'lang']),
    conditions() {
      return [
        {
          icon: 'location',
          iconComponent: 'wetIcon',
          title: this.droughtRisk.location,
          link: {
            title: this.apiData.location?.address,
          },
        },
        {
          icon: 'crop',
          iconComponent: 'wetIcon',
          title: this.droughtRisk.crops,
          link: {
            title: `${(this.apiData.crops || []).map((c) => `${c.hectar} ha ${this.cropsTitles[c.type]}`).join(', ')}`,
          },
        },
      ];
    },
    crops() {
      return this.strings.drought?.crops || [];
    },
    cropsTitles() {
      return Object.fromEntries(this.crops.map((c) => [c.id, c.title]));
    },
    droughtRisk() {
      return this.strings.droughtRisk || {};
    },
    stringsCommonApp() {
      return this.strings.commonApp || {};
    },
    askResultMessage() {
      let message = '';
      switch (this.cfUserAnswer) {
      case true:
        message = this.droughtRisk.feedback_result_yes;
        break;
      case false:
        message = this.droughtRisk.feedback_result_no;
        break;
      default:
        message = '';
        break;
      }
      return message;
    },
    introItems() {
      let items = [];
      switch (this.cfUserAnswer) {
      case null:
        items = [{
          title: this.droughtRisk?.answer_please,
          el: '#drought-risk-question',
        }];
        break;
      case false:
        items = [{
          title: this.droughtRisk?.feedback_please,
          el: '#wet-dr-feedback-form',
        }];
        break;
      default:
        break;
      }
      return items;
    },
    allowNext() {
      return this.feedbackSentStatus || this.cfUserAnswer;
    },
    riskData() {
      return {
        crops: this.apiData.crops?.map((c) => ({
          type: c.type,
          hectar: c.hectar,
          start: c.start,
          end: c.end,
          risk_amount: this.maxAmounts[c.type],
        })),
      };
    },
  },
  watch: {
    riskData: {
      handler(riskData) {
        if (riskData.crops.length > 0 && riskData.crops.every((c) => c.risk_amount !== undefined)) {
          this.saveRiskData(riskData);
        }
      },
    },
  },
  validations() {
    return {
      feedbackForm: {
        phone,
        message: {
          required,
        },
      },
    };
  },
  created() {
    this.init();
  },
  destroyed() {
    // unwatch next & back buttons click watcher
    this.$root.$off('buttonBack', this.back);
    this.$root.$off('buttonNext', this.next);
  },
  methods: {
    ...mapActions({
      getProductSettings: 'settings/getProductSettings',
    }),
    ...mapMutations(['SET_INTRO']),
    async getRiskApi() {
      const res = await this.callApi(this.getCropSelectionApi);
      const crops = res.data.crops.length > 0 ? res.data.crops : [];
      this.apiData = {
        ...res.data,
        crops,
      };
    },
    async init() {
      await Promise.all([
        this.getWordPressStrings(
          [wpSection.DROUGHT, wpSection.DROUGHT_RISK],
        ),
        this.getRiskApi(),
        this.getYieldHistory(),
        this.getProductSettings('drought'),
        this.$root.$on('buttonNext', this.next),
        this.$root.$on('buttonBack', this.back),
        this.$emit('update:nextDisabled', false),
      ]);
    },
    back() {
      this.$router.push({ name: 'crop-location' });
    },
    async getYieldHistory() {
      const res = await this.callApi(this.yieldApi);
      const apiYieldHistory = res.data.reduce((acc, cur) => {
        const accumulator = acc;
        const [entries] = Object.entries(cur);
        [, accumulator[entries[0]]] = entries;
        return accumulator;
      }, {});

      this.apiYieldHistory = apiYieldHistory;
    },
    async saveRiskData(data) {
      return this.callApi({ url: this.saveRiskDataApi, method: 'POST', data });
    },
    onCfYes() {
      this.cfUserAnswer = true;
      LogService.log('cfUserAnswer', this.cfUserAnswer);
    },
    onCfNo() {
      this.cfUserAnswer = false;
    },
    next() {
      LogService.log('DroughtRisk, next');
      if (!this.allowNext) {
        this.initIntro();
        return;
      }
      if (!this.showCropOverview) {
        this.showCropOverview = true;
        return;
      }

      const row = this.$refs['per-crop-overview']?.riskTable?.body[0];
      const crop = row?.id.text;
      const year = this.$refs['per-crop-overview']?.availableInsureYears[crop]?.[0];
      this.onInsure({ row, year });
    },
    initIntro() {
      const items = this.introItems;
      const intro = { title: 'intro', items };
      this.SET_INTRO(intro);
    },
    onFeedbackSubmit() {
      this.$v.$touch();
      if (this.$v.feedbackForm.$error) return;
      const data = {
        phone: this.feedbackForm.phone,
        comment: this.feedbackForm.message,
      };
      this.saveRiskData(data);
      this.feedbackSentStatus = true;
    },
    async onInsure({ row, year }) {
      // order product
      const id = row.id.text;
      const crop = this.apiData?.crops?.filter((c) => c.type === id)[0];
      await this.$login();
      await this.orderProduct(crop.type, crop.start, crop.end, year);
      await this.callSourceTypesApi();

      // call find api
      const userStatus = await this.findLocationApiCall();

      // call optimizer api
      const ApiDataOptimizer = await this.callOptimizer();

      // find optimal contract parameter
      // eslint-disable-next-line max-len
      const optimal = ApiDataOptimizer.filter((el) => el.f_max_ratio_bundle_avg_hist_payout_bundle_min_price)[0] || {};
      const maxHistPayout = Math.max(...Object.values(optimal.bundle_hist_payouts || {})) || 0;
      const multiplicator = maxHistPayout === 0 ? 5 : optimal.bundle_maximal_payout / maxHistPayout;

      // call GET order api
      const ApiDataOrder = await this.getOrderApi();

      // calculate maximal payout
      const maximalValueMaxContractPayout = ApiDataOrder
        .settings.order?.maximal_value_max_contract_payout;
      const maxAmount = this.maxAmounts[row.id.text];
      const maximalPayout = Math.min(
        Math.round(maxAmount * multiplicator * 100) / 100,
        maximalValueMaxContractPayout,
      );

      if (this.$isDev) {
        console.log({
          optimal,
          maxHistPayout,
          multiplicator,
          maximalValueMaxContractPayout,
          maxAmount,
          maximalPayout,
        });
      }

      // set order parameter
      await this.postOrderSetParameterApi({ maximal_payout: maximalPayout });

      // check user status and redirect
      let name = 'letus';
      if (['0', '1', '3'].includes(userStatus.toString())) {
        name = 'done';
      }
      this.$router.push({ name, params: { ...this.$route.params } });
    },
    async orderProduct(crop, start, end, year) {
      const dates = Array.from(this.$moment.range(start, end).by('days')).map((d) => d
        .set({ year })
        .format('YYYY-MM-DD'));
      const data = {
        business: {
          type: businessType.crop,
          subtype: crop,
        },
        weather: {
          type: availableWeatherTypes.rain,
          direction: availableWeatherDirs.norain,
        },
        dates,
        partner_code: this.partnerCode,
      };
      return postOrderProduct(data);
    },
    async callSourceTypesApi() {
      const url = this.soureTypesApi;
      const res = await this.callApi({ url });
      const searchCountries = res.data?.insurance_country?.map((c) => c.code);
      if (searchCountries) this.searchCountries = searchCountries;
    },
    /**
     * @returns userStatus
     */
    async findLocationApiCall() {
      const { location } = this.apiData;
      // call API station
      // if (!location.street || !location.house)
      //   return Promise.reject(new Error('Street and House needed'));

      // call api
      const data = {
        location,
      };
      let res = null;
      res = await this.callApi({ url: this.findStaApi, method: 'post', data });
      return res.data.user.status;
    },
    async callOptimizer() {
      // search optimized initial data
      if (this.$isDev) console.log('Loading optimizer API...');

      const data = {
        start_year: process.env.VUE_APP_OPTIMIZER_START_YEAR,
        end_year: process.env.VUE_APP_OPTIMIZER_END_YEAR,
      };

      const res = await this.callApi({ url: this.optimizerApi, method: 'post', data });
      return res.data || [];
    },
    async getOrderApi() {
      const res = await this.callApi(this.orderApi);
      return res.data || {};
    },
    async postOrderSetParameterApi(data) {
      await this.callApi({ url: this.orderSetParameterApi, method: 'post', data });
    },
  },
};
</script>

<style lang="scss">
</style>
